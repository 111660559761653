import {
  LeaderboardSubPage,
  I18nMessageFunction,
  LeaderboardDuration
} from '@/types'

export default {
  leaderboard: {
    title: 'Leaderboard',
    description: 'Top Traders on Helix',
    unranked: 'Unranked',
    screenshot: 'Screenshot',
    startTrading: 'Start Trading',
    tradeAndWin:
      'Not on the leaderboard yet?  Make winning trades on Helix to claim your spot!',
    timeStamp: 'Time Stamp',
    helix: 'helixapp.com',
    rulesTermsAndConditions: 'Rules, Terms, and Conditions',
    blocked: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        'Residents of the United States, the United Kingdom, and certain other countries listed in the official ',
        named('terms'),
        ' of the Competition are ineligible to participate in or win the Competition.'
      ]),
    pnl: {
      duration: {
        [LeaderboardDuration.OneDay]: 'Today',
        [LeaderboardDuration.All]: 'All Time',
        [LeaderboardDuration.OneWeek]: 'This Week',
        [LeaderboardDuration.OneMonth]: 'This Month'
      },
      share: 'Share',
      noPnlData: 'No PnL results found',

      currentDuration: ({ named }: I18nMessageFunction) =>
        `Trading PnL (${named('duration')})`,
      timePeriod: ({ named }: I18nMessageFunction) =>
        `Time Period: ${named('startDate')} - ${named('endDate')}`,
      lastUpdated: ({ named }: I18nMessageFunction) =>
        `Last Updated: ${named('lastUpdatedDate')}`
    },

    competition: {
      unranked: 'Unranked',
      keepGoing: 'Keep going 💪',
      currentLeader: 'Current leader',
      currentLeaderFlame: '🔥🔥',
      currentLeaderMobile: '🔥',
      competitionBeginning: 'The competition is about to begin...',
      competitionHasBegun: 'The competition has begun, good luck!',
      competitionMaintenance:
        'Crunching the latest numbers for you, the leaderboard will be back up shortly ...',
      noVolumeData: 'No trading competition results found',
      competitionDuration: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate(['Time Remaining: ', named('duration')]),
      firstHourOfCampaign: ({ named }: I18nMessageFunction) =>
        `The competition is now live. The leaderboard will begin displaying campaign stats at ${named(
          'afterFirstHour'
        )}.`,
      additionalEntriesTooltip:
        'These entries are bonus entries earned by participating in one or more bonus periods during the competition.',
      termsAndConditionsApply: 'Terms and conditions apply',
      banner: {
        title: 'Trade Like a G',
        blog: 'blog',
        ended: 'Ended',
        description: ({ named, interpolate }: I18nMessageFunction) =>
          interpolate([
            'Trade for a chance to win a G-Wagon! From October 1st at 14:00 UTC to October 22nd at 14:00 UTC, trade for your chance to claim one of two G-Wagons—one for the top PnL trader and another for a lucky giveaway winner. Every $10 in trading volume earns an entry. For more details, visit the ',
            named('blog'),
            '.'
          ])
      },
      share: {
        raffleTickets: ({ named, interpolate }: I18nMessageFunction) =>
          interpolate(["I've already got ", named('tickets'), ' entries']),
        likeAG: 'like a G',
        joinCompetition:
          'Join Helix\'s "like a G" trading competition for a chance to win a Mercedes G-Wagon!'
      }
    },

    tabs: {
      [LeaderboardSubPage.Pnl]: 'PnL Leaderboard',
      [LeaderboardSubPage.Competition]: 'Trading Competition'
    },

    header: {
      rank: 'Rank',
      address: 'Address',
      tradingPnl: 'Trading PnL (USD)',
      weeklyROI: 'Weekly ROI',
      allMarkestVolume: 'All Markets Trading Volume (USD)',
      volume: 'Trading Volume (USD)',
      numberOfEntries: 'Number of Entries',
      entries: 'Entries'
    },

    footer: {
      onlyTop100:
        'The leaderboard only displays up to the top 100 participants.',
      onlyTop100Connect: 'To view your stats, connect your wallet.'
    },

    myStats: 'My Stats',
    viewMore: 'View More'
  }
}
